.container-get-info {
  height: 395px;
  width: 315px;
  background-color: white;
  color: #2F363F;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  position: absolute;
  left: 700px;
  top: 100px;

}

.entry-property {
  margin-top: 24px;
}

.icon-holder {
  margin: 20px auto;
}