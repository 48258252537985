.search-result {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  align-content: center;
}

.no-entries {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 40px;
  align-content: center;
}