.context-menu {
  display: flex;
  flex-direction: column;
  width: 160px;
  background-color: #FFFFFF;
  color: #535B62;
  font-size: 16px;
  text-align: center;
  border: 1px solid #DDE0E4;
  position: absolute;
}

.menu-item {
  flex: 1;
  padding: 15px;
  border: 0.04px solid #DDE0E4;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #EEEFF1;
}

.delete {
  color: #FF5942;
}