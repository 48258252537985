.path-container {
  display: flex;
}

.up-arrow {
  display: flex;
  margin-left: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.path {
  display: flex;
  margin-left: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}