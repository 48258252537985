.app-container {
  display: flex;
  height: 100vh;
}

.file-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.add-logo {
  height: 90px;
  margin: 20px;
  margin-left: 28px;
  cursor: pointer;
}

.root {
  margin-top: 20px;
  margin-left: 35px;
  margin-bottom: 20px;
}

.left-section {
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  overflow-x: scroll;
  background-color: #282C2C;
}

.right-section {
  flex-wrap: wrap;
  width: calc(100% - 700px);
}

li {
  display: flex;
  align-items: center;
}

.file-icon {
  margin-left: 5px;
}

/*Func*/

.item ul, .multi-level input[type="checkbox"] {
    display: none;
}
.multi-level, .item input:checked ~ ul {
    display: block;
}

/*Arrow*/

.arrow {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    float: left;
    z-index: 0;
    margin: 6px 1em 0 2em;
    margin-left: 0;
}
.item input + .arrow {
    transform: rotate(-180deg);
    transition: 0.1s;
}
.item input:checked + .arrow {
    transform: rotate(0deg);
    transition: 0.1s;
}



/*Styles*/

label:hover {
    cursor: pointer;
}
label {
    width: 100%;
    display: block;
    z-index: 3;
    position: relative;
}

.multi-level ul, .multi-level li, label {
    line-height: 35px;
    margin: 0;
    padding: 0 15px;
    list-style: none;
    text-decoration: none;
    color: #90A4AE;
    font-weight: 100;
    width: 90%;
}
.item ul {
    padding: 0 0.25em;
}
.multi-level li a {
    line-height: 35px;
    margin: 0;
    padding: 0 35px;
    list-style: none;
    text-decoration: none;
    color: #90A4AE;
    font-weight: 100;
}