.folder {
  margin: 20px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.folder:hover {
  background-color: rgb(23, 108, 165);
  border-radius: 8px;
}

