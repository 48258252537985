body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-container {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
}

.file-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.add-logo {
  height: 90px;
  margin: 20px;
  margin-left: 28px;
  cursor: pointer;
}

.root {
  margin-top: 20px;
  margin-left: 35px;
  margin-bottom: 20px;
}

.left-section {
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  overflow-x: scroll;
  background-color: #282C2C;
}

.right-section {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: calc(100% - 700px);
}

li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.file-icon {
  margin-left: 5px;
}

/*Func*/

.item ul, .multi-level input[type="checkbox"] {
    display: none;
}
.multi-level, .item input:checked ~ ul {
    display: block;
}

/*Arrow*/

.arrow {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    float: left;
    z-index: 0;
    margin: 6px 1em 0 2em;
    margin-left: 0;
}
.item input + .arrow {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transition: 0.1s;
    transition: 0.1s;
}
.item input:checked + .arrow {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.1s;
    transition: 0.1s;
}



/*Styles*/

label:hover {
    cursor: pointer;
}
label {
    width: 100%;
    display: block;
    z-index: 3;
    position: relative;
}

.multi-level ul, .multi-level li, label {
    line-height: 35px;
    margin: 0;
    padding: 0 15px;
    list-style: none;
    text-decoration: none;
    color: #90A4AE;
    font-weight: 100;
    width: 90%;
}
.item ul {
    padding: 0 0.25em;
}
.multi-level li a {
    line-height: 35px;
    margin: 0;
    padding: 0 35px;
    list-style: none;
    text-decoration: none;
    color: #90A4AE;
    font-weight: 100;
}
.file {
  margin: 20px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.file:hover {
  background-color: rgb(23, 108, 165);
  border-radius: 8px;
}
.folder {
  margin: 20px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.folder:hover {
  background-color: rgb(23, 108, 165);
  border-radius: 8px;
}


.path-container {
  display: -webkit-flex;
  display: flex;
}

.up-arrow {
  display: -webkit-flex;
  display: flex;
  margin-left: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.path {
  display: -webkit-flex;
  display: flex;
  margin-left: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.search-box {
  width: 400px;
  height: 100vh;
  margin-right: 0;
  border-left: 1px solid #7a7a7a;
  text-align: center;
}

.input-box {
  margin-top: 50px;
  background: url(/static/media/search.f47dc9ac.svg) no-repeat scroll 10px 10px;
  width: 250px;
  padding-left: 40px;
  height: 40px;
  color: whitesmoke;
  font-size: 15px;
}

.input-box::-webkit-input-placeholder {
  font-size: 15px;
  color: whitesmoke;
}

.input-box::-ms-input-placeholder {
  font-size: 15px;
  color: whitesmoke;
}

.input-box::placeholder {
  font-size: 15px;
  color: whitesmoke;
}

.search-result {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  -webkit-align-content: center;
          align-content: center;
}

.no-entries {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 40px;
  -webkit-align-content: center;
          align-content: center;
}
.container-float {
  height: 450px;
  width: 315px;
  background-color: white;
  color: #2F363F;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  position: absolute;
  left: 300px;
  top: 100px;
}

.header {
  padding-top: 25px;
  text-align: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.title {
  margin-left: 85px;
  font-size: 18px;
  vertical-align: top;
}

.close-btn {
  margin-left: 70px;
  cursor: pointer;
}

.button-container {
  width: 130px;
  display: -webkit-flex;
  display: flex;
  margin: 16px auto;
  font-size: 14px;
}

.file-btn {
  -webkit-flex: 1 1;
          flex: 1 1;
  border-radius: 7px 0 0 7px;
  border: 1px solid #DDE0E4;
  padding: 5px;
  cursor: pointer;
}

.folder-btn {
  -webkit-flex: 1 1;
          flex: 1 1;
  border-radius: 0 7px 7px 0;
  border: 1px solid #DDE0E4;
  padding: 5px;
  cursor: pointer;
}

.active {
  background-color: #4AB7FF;
  color: #FFFFFF;
}

.input {
  width: 196px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #DDE0E4;
  margin-top: 16px;
  padding-left: 21px;
}

.input::-webkit-input-placeholder {
  padding: 21px 0;
  font-size: 14px;
  color: #AFB2B6;
}

.input::-ms-input-placeholder {
  padding: 21px 0;
  font-size: 14px;
  color: #AFB2B6;
}

.input::placeholder {
  padding: 21px 0;
  font-size: 14px;
  color: #AFB2B6;
}

.input:focus {
  outline: none;
}

.create-btn {
  width: 220px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #DDE0E4;
  background-color: #4AB7FF;
  margin: 16px auto;
  cursor: pointer;
  text-align: center;
  vertical-align: auto;
  color: #FFFFFF;
  font-size: 14px;
}

.create-btn:focus {
  outline: none;
}
.context-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 160px;
  background-color: #FFFFFF;
  color: #535B62;
  font-size: 16px;
  text-align: center;
  border: 1px solid #DDE0E4;
  position: absolute;
}

.menu-item {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 15px;
  border: 0.04px solid #DDE0E4;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #EEEFF1;
}

.delete {
  color: #FF5942;
}
.container-get-info {
  height: 395px;
  width: 315px;
  background-color: white;
  color: #2F363F;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  position: absolute;
  left: 700px;
  top: 100px;

}

.entry-property {
  margin-top: 24px;
}

.icon-holder {
  margin: 20px auto;
}
