.container-float {
  height: 450px;
  width: 315px;
  background-color: white;
  color: #2F363F;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  position: absolute;
  left: 300px;
  top: 100px;
}

.header {
  padding-top: 25px;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
}

.title {
  margin-left: 85px;
  font-size: 18px;
  vertical-align: top;
}

.close-btn {
  margin-left: 70px;
  cursor: pointer;
}

.button-container {
  width: 130px;
  display: flex;
  margin: 16px auto;
  font-size: 14px;
}

.file-btn {
  flex: 1;
  border-radius: 7px 0 0 7px;
  border: 1px solid #DDE0E4;
  padding: 5px;
  cursor: pointer;
}

.folder-btn {
  flex: 1;
  border-radius: 0 7px 7px 0;
  border: 1px solid #DDE0E4;
  padding: 5px;
  cursor: pointer;
}

.active {
  background-color: #4AB7FF;
  color: #FFFFFF;
}

.input {
  width: 196px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #DDE0E4;
  margin-top: 16px;
  padding-left: 21px;
}

.input::placeholder {
  padding: 21px 0;
  font-size: 14px;
  color: #AFB2B6;
}

.input:focus {
  outline: none;
}

.create-btn {
  width: 220px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #DDE0E4;
  background-color: #4AB7FF;
  margin: 16px auto;
  cursor: pointer;
  text-align: center;
  vertical-align: auto;
  color: #FFFFFF;
  font-size: 14px;
}

.create-btn:focus {
  outline: none;
}